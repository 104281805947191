function toggleMenu(elem) {
    var vh = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--vh').trim()) * 100;
    var hhxs = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--hhxs').trim());
    var height = vh;

    elem.classList.toggle("active");
    var content = elem.nextElementSibling;
    if (content.style.maxHeight) {
        content.style.maxHeight = null;
    } else {
        content.style.maxHeight = height + "px";
    }
}

function handleClick() {
    toggleMenu(this);
}

export const Collapsible = () => {

    const coll = document.querySelectorAll(".collapsible");



    coll.forEach((elem) => {
        elem.addEventListener("click", handleClick);

        window.addEventListener('resize', () => {
            var vh = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--vh').trim()) * 100;
            var hhxs = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--hhxs').trim());
            var height = vh;
            var content = elem.nextElementSibling;

            if (elem.classList.contains('active')) {
                content.style.maxHeight = height + "px";
            }
        });
    });


}

export const CloseMenu = () => {

    const coll = document.querySelectorAll(".collapsible");

    coll.forEach((elem) => {
        elem.classList.remove("active");
        var content = elem.nextElementSibling;
        if (content.style.maxHeight) {
            content.style.maxHeight = null;
        }
        elem.removeEventListener("click", handleClick);
    });
}


export const SetLang = (lang) => {
    console.log(lang);
    document.documentElement.setAttribute("lang", lang);

    const menuLinks = document.querySelectorAll('.main-menu .main-menu-link');
    menuLinks.forEach((elem) =>{
        const href =  elem.getAttribute('href');
        var newhref;
        if(lang == 'en'){
            newhref = href.replace('/fr/', '/en/');
        }else if(lang == 'fr'){
            newhref = href.replace('/en/', '/fr/');
        }

        elem.setAttribute('href', newhref);


    });
}